import { Checkbox, Chip, createStyles, makeStyles, TextField, Theme } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IRootState } from 'config/store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { sortByLabel } from 'shared/utils/data-utils';
import { ILabelValueOption } from 'shared/utils/select-utils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
      width: '32px',
      height: '32px'
    },
    chip: {
      borderRadius: '10px',
      maxWidth: 100
    },
    productsFilter: {
      height: '29px',
      minWidth: 260,
      maxWidth: 300,
      paddingLeft: theme.spacing(3),
      marginTop: 0
    },
    empty: {
      color: 'rgba(0, 0, 0, 0.5)'
    }
  })
);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface IProductFilterProps {
  onChange: (selection: ILabelValueOption<string>[]) => void;
  placeholder?: string;
}

const ProductFilter = (props: IProductFilterProps) => {
  const products = useSelector(({ product }: IRootState) => product.products);

  const classes = useStyles();
  const { t } = useTranslation();
  if (products.length < 2) {
    return null;
  }

  const sortedProduct: ILabelValueOption<string>[] = [...products].map(item => ({
    label: item.device_content,
    value: item.device_content_reference
  }));
  sortedProduct.sort(sortByLabel);

  const onChange = (event: React.ChangeEvent<any>, selection: any) => {
    props.onChange(selection);
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      limitTags={1}
      options={sortedProduct}
      getOptionLabel={option => option.label}
      renderInput={params => (
        <TextField
          {...params}
          placeholder={t('device_content', { count: 10 })}
          className={classes.productsFilter}
          style={{ width: '250px' }}
        />
      )}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          {option.label}
        </React.Fragment>
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            label={option.label}
            size="small"
            className={classes.chip}
          />
        ));
      }}
      getOptionSelected={(option, value) => {
        return value.value === option.value;
      }}
      onChange={onChange}
    />
  );
};

export default ProductFilter;
