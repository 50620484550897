import { IRootState } from 'config/store';
import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { IDataTableColumn } from 'react-data-table-component';
import DataTable from 'shared/widgets/dataTable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AutoOrderHistory } from 'shared/model/autoOrder.model';
import { fetchAutoOrdersHistory } from 'shared/reducers/autoOrdersSlice';
import { APP_LOCAL_DATE_FORMAT, APP_TIMESTAMP_FORMAT, formatDate } from 'shared/utils/date-utils';
import SearchIcon from '@material-ui/icons/Search';
import {
  Box,
  createStyles,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  useTheme
} from '@material-ui/core';
import GroupFilter from 'shared/widgets/groups/groupFilter';
import { ILabelValueOption } from 'shared/utils/select-utils';
import { DatePicker } from '@material-ui/pickers';
import StatusFilter from 'shared/widgets/autoorders/statusFilter';
import { workspaceTruckQuantity } from 'shared/utils/workspace-utils';
import ProductFilter from 'shared/widgets/products/productFilter';
import useInterval from 'shared/hooks/useInterval';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePicker: {
      height: '29px',
      maxWidth: 120,
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    },
    searchField: {
      marginTop: 0,
      width: 120,
      marginBottom: theme.spacing(1)
    }
  })
);

const AutoOrdersMonitoring = () => {
  const { t } = useTranslation();
  const loading = useSelector(({ autoOrder }: IRootState) => autoOrder.loading);
  const autoOrderHistory = useSelector(({ autoOrder }: IRootState) => autoOrder.autoOrderHistory);
  const dispatch = useDispatch();
  const devices = useSelector(({ devices }: IRootState) => devices.devices);
  const products = useSelector(({ product }: IRootState) => product.products);
  const settings = useSelector(({ workspace }: IRootState) => workspace.settings);
  const truckQuantity = workspaceTruckQuantity(settings);
  const classes = useStyles();
  const [groupsFilter, setGroupsFilter] = useState<string[]>([]);
  const [productsFilter, setProductsFilter] = useState<string[]>([]);
  const [status, setStatus] = useState<string>('null');
  const [startDate, setStartDate] = useState(moment().subtract(2, 'month').startOf('day'));
  const [orderToSearch, setOrderToSearch] = useState<string>('');
  const theme = useTheme();
  const columns: IDataTableColumn<AutoOrderHistory>[] = useMemo(() => {
    const knownErrorCode = {
      10401: t('zonda.zero_dispocard'),
      10201: t('zonda.mandatory_field_missing'),
      10202: t('zonda.mandatory_field_error'),
      10101: t('zonda.technical_error'),
      99001: t('zonda.shipto_field_missing'),
      99002: t('zonda.soldto_field_missing'),
      99003: t('zonda.product_name_field_missing'),
      99004: t('zonda.product_number_field_missing'),
      99005: t('zonda.no_contract_material')
    };
    return [
      {
        selector: 'created_at',
        name: t('created_at'),
        sortable: true,
        grow: 1,
        format: (row: any) => {
          return formatDate(row.created_at, APP_TIMESTAMP_FORMAT);
        }
      },
      {
        selector: 'orderNo',
        name: t('order_number'),
        sortable: true,
        grow: 2.5,
        format: row => {
          return row.code !== 10000 && row.orderNo === null ? (
            <Box display="flex" alignItems="center">
              {row.code !== null
                ? knownErrorCode[row.code as keyof typeof knownErrorCode]
                : t('autoorder_not_created')}
            </Box>
          ) : (
            row.orderNo
          );
        }
      },
      {
        selector: 'productNo',
        name: t('reference'),
        sortable: true,
        grow: 2,
        format: row => {
          return (
            <Box display="flex" alignItems="center">
              {row.materialNo}
            </Box>
          );
        }
      },
      {
        selector: 'product',
        name: t('device_content'),
        sortable: true,
        grow: 2,
        format: row => {
          const materialName = products.find(
            product => product.device_content_reference === row.materialNo
          )?.device_content;
          return (
            <Box display="flex" alignItems="center">
              {materialName}
            </Box>
          );
        }
      },
      {
        selector: 'quantity',
        name: t('string_workspace_filling_unit', { value: t('ordered_quantity') }),
        sortable: true,
        grow: 2,
        format: () => (
          <Box display="flex" alignItems="center">
            {truckQuantity}
          </Box>
        )
      },
      {
        selector: 'plant',
        name: t('plant'),
        sortable: true,
        grow: 3,
        format: row => {
          const siteName = devices.find(
            device => device.device_reference === row.idDevice
          )?.farm_name;
          return (
            <Box display="flex" alignItems="center">
              {siteName}
            </Box>
          );
        }
      },
      {
        id: 'siloName',
        selector: 'idDevice',
        name: t('silo'),
        sortable: true,
        grow: 2,
        format: row => {
          const deviceName = devices.find(
            device => device.device_reference === row.idDevice
          )?.device_name;
          return (
            <Box display="flex" alignItems="center">
              {deviceName}
            </Box>
          );
        }
      }
    ];
  }, [t, devices, truckQuantity, products]);

  const loadData = useCallback(async () => {
    dispatch(fetchAutoOrdersHistory(startDate));
  }, [dispatch, startDate]);

  useInterval(loadData);

  const title = React.useMemo(() => {
    const onChange = (search: string) => {
      setOrderToSearch(search);
    };
    const onGroupsChange = (selection: ILabelValueOption<string>[]) => {
      const filter = selection.map(item => item.value) as string[];
      setGroupsFilter(filter);
    };
    const onProductChange = (selection: ILabelValueOption<string>[]) => {
      const filter = selection.map(item => item.value) as string[];
      setProductsFilter(filter);
    };
    const onStatusChange = (event: React.ChangeEvent<any>) => {
      setStatus(event.target.value);
    };
    return (
      <Box display="flex" flexDirection="row" justifyContent="start">
        {t('auto_orders_history')}

        <DatePicker
          disableToolbar
          value={startDate}
          onChange={(value: any) => setStartDate(value)}
          format={APP_LOCAL_DATE_FORMAT}
          disableFuture
          margin="none"
          variant="inline"
          className={classes.datePicker}
        />

        <TextField
          className={classes.searchField}
          size="small"
          placeholder={t('search')}
          onChange={e => onChange(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="disabled" />
              </InputAdornment>
            )
          }}
        />
        <ProductFilter onChange={onProductChange} />
        <GroupFilter onChange={onGroupsChange} />
        <StatusFilter status={status} onChange={onStatusChange} />
      </Box>
    );
  }, [t, classes, startDate, status]);

  const filterDevices = (autoorder: AutoOrderHistory) => {
    if (groupsFilter.length > 0) {
      const device = devices.find(device => device.device_reference === autoorder.idDevice);
      return device && device.farm_id && groupsFilter.includes(device.farm_id);
    }
    return true;
  };

  const filterProducts = (autoorder: AutoOrderHistory) => {
    if (productsFilter.length > 0) {
      return autoorder?.materialNo && productsFilter.includes(autoorder?.materialNo);
    }
    return true;
  };

  const filterStatus = (autoorder: AutoOrderHistory) => {
    if (status === 'null') return true;
    if (status === 'KO') return autoorder.code !== 10000 && autoorder.orderNo === null;
    if (status === 'OK') return autoorder.code === 10000 && autoorder.orderNo !== null;
    return true;
  };

  const filteredData = autoOrderHistory.filter(autoorder => {
    const filter = orderToSearch.toLocaleLowerCase();
    const materialName = products.find(
      product => product.device_content_reference === autoorder.materialNo
    )?.device_content;
    return (
      (filter.length > 0
        ? autoorder.orderNo?.toString().toLowerCase().includes(filter) ||
          devices.some(
            device =>
              (device.device_reference === autoorder.idDevice &&
                device.device_name.toLowerCase().includes(filter)) ||
              autoorder.materialNo?.toLowerCase().includes(filter) ||
              materialName?.toLowerCase().includes(filter)
          )
        : true) &&
      filterDevices(autoorder) &&
      filterStatus(autoorder) &&
      filterProducts(autoorder)
    );
  });

  const conditionalRowStyles = [
    {
      when: (row: AutoOrderHistory) => row.code !== 10000 && row.orderNo === null,
      style: {
        backgroundColor: theme.palette.error.light
      }
    }
  ];

  return (
    <DataTable
      title={title}
      defaultSortField="created_at"
      defaultSortAsc={false}
      columns={columns}
      data={filteredData}
      progressPending={loading}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
};

export default AutoOrdersMonitoring;
